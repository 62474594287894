import ClickOutside from 'click-outside-vue3';

export default {
    name: 'kk-popmenu',
    directives: {
        ClickOutside: ClickOutside.directive,
    },
    props: {
        /**
         * Pixels below parent element.
         * @default 30px
         */
        top: {
            type: Number,
            default: 30,
        },
        // By default, pop menu will hide when clicked inside, can be overwritten by setting this to false.
        hideOnClick: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        onClick(e) {
            this.visible = !this.visible;
            e.stopPropagation();
        },
        close() {
            this.visible = false;
        },
        popClick(e) {
            if (this.hideOnClick) {
                this.close();
            }
            e.stopPropagation();
        },
    },
};
