import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import ClickOutside from 'click-outside-vue3';

library.add(faChevronDown);

// @vue/component
export default {
    name: 'kk-popup-desktop',
    Up: 'Up',
    Down: 'Down',

    components: {
        FontAwesomeIcon,
    },

    directives: {
        ClickOutside: ClickOutside.directive,
    },

    props: {
        /**
         * z-index base for the component.
         */
        zIndex: {
            type: Number,
            default: 1,
        },
        arrow: {
            type: Boolean,
            default: true,
        },
        direction: {
            type: String,
            default: '',
            validator: value => ['up', 'down', ''].includes(value),
        },
    },

    data() {
        return {
            latestEvent: '',
            windowInnerWidth: 0,
            computedDirection: this.$options.Down,
            parentHeight: 0,
        };
    },

    computed: {
        conditionalClasses() {
            return {
                up: this.computedDirection === this.$options.Up,
                down: this.computedDirection === this.$options.Down,
            };
        },
    },

    mounted() {
        if (this.direction === 'up') {
            this.computedDirection = this.$options.Up;
        } else if (this.direction === 'down') {
            this.computedDirection = this.$options.Down;
        } else {
            this.computedDirection = this.calculateDirection();
        }

        let rect = this.$el.parentNode.getBoundingClientRect();
        this.parentHeight = rect.height;
    },

    methods: {
        emitHide() {
            this.$emit('hide');
        },

        /**
         * Decide if the popup should point up or down
         */
        calculateDirection() {
            let rect = this.$el.getBoundingClientRect();

            if (rect.y > (window.innerHeight * 2.7 / 5.0)) {
                return this.$options.Up;
            } else {
                return this.$options.Down;
            }
        },

        onKeyEscape() {
            this.emitHide();
        },
    },
};
