<template>
    <!--
        Input event
        @event input
    -->
    <span
        :class="[
            'kk-datepicker',
            {
                'full-width': fullWidth,
                'active': isActive,
                'only-time': onlyTime,
                'jumping-year-fix': yearPopoverFix,
                'single-date': !range,
            },
        ]"
        v-bind="$attrs"
    >
        <date-picker
            ref="datepicker"
            :initial-page="initialPage"
            :attributes="attributesWithHolyDays"
            color="blue"
            is24hr
            :is-range="range"
            :is-required="isRequired"
            :locale="lang"
            :first-day-of-week="2"
            :masks="{
                modelValue: displayFormat || 'DD.MM.YYYY',
                input: displayFormat || 'DD.MM.YYYY',
                inputDateTime24hr: displayFormat || 'DD.MM.YYYY HH:mm',
            }"
            :max-date="maxDate"
            :min-date="minDate"
            :disabled-dates="disabledDates"
            :mode="mode"
            :popover="{
                transition: 'none',
                placement: popoverPlacement,
                visibility: 'click',
            }"
            :show-iso-weeknumbers="showWeeks"
            trim-weeks
            :model-value="value"
            :disabled="disabled"
            @dayclick="handleDayClicked"
            @update:model-value="handleInput"
            @popover-did-hide="handlePopoverDidHide"
            @popover-did-show="isActive = true"
        >
            <template #default="{ inputValue, togglePopover }">
                <!--
                @slot Calendar popup trigger
                @binding {object} All scopes from v-calendar are forwarded
            -->
                <slot name="default" v-bind="{ inputValue, togglePopover }">
                    <div
                        v-if="iconOnly"
                        class="icon-wrapper"
                        @click="openCalendarPopover($event, togglePopover)"
                    >
                        <fa-icon :icon="['fal', 'calendar']" />
                    </div>
                    <div
                        v-else
                        class="input-wrapper"
                    >
                        <input
                            class="default-calendar-input"
                            :disabled="disabled ? true : null"
                            :value="range ? rangeValue(inputValue) : inputValue"
                            :placeholder="displayedPlaceholder"
                            @click="openCalendarPopover($event, togglePopover)"
                        >
                        <!-- @slot Clear button slot -->
                        <slot v-if="clearable && modelValue && !disabled" name="clear-button">
                            <font-awesome-icon
                                class="clear-button"
                                :icon="['fal', 'times-circle']"
                                role="button"
                                :disabled="disabled ? true : null"
                                @click="$emit('update:modelValue', null)"
                            />
                        </slot>
                    </div>
                </slot>
            </template>
            <template #footer>
                <slot name="footer" v-bind="{ datepicker: $refs.datepicker }">
                    <section class="footer">
                        <div v-if="range">
                            <span>{{ trans('shared.Dato periode') }}:</span>
                            <div>
                                <v-select
                                    name="date-range"
                                    :model-value="currentPeriodDateRange"
                                    :options="dateRangeOptions"
                                    :reduce="dateRange => dateRange.id"
                                    :clearable="false"
                                    :searchable="false"
                                    label="title"
                                    class="select"
                                    :selectable="option => !option.disabled"
                                    @update:model-value="setRangeValue"
                                />
                            </div>
                        </div>
                        <kk-button :full="fullWidth" @click.prevent="$refs.datepicker.hidePopover()">
                            {{ trans('shared.OK') }}
                        </kk-button>
                    </section>
                </slot>
            </template>
        </date-picker>
    </span>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { DatePicker } from 'v-calendar';
import { getHolyDays } from '../../lib/holy-days';
import kkButton from '../kk-button/kk-button.vue';
import vSelect from 'vue-select';
import { DateRangeOptionTypes, getDateRangeOptions } from '../../../../vue/helpers/date-range-options';
import 'v-calendar/style.css';

library.add(faTimesCircle);

export default {
    name: 'kk-datepicker',
    components: {
        DatePicker,
        FontAwesomeIcon,
        kkButton,
        vSelect,
    },

    inheritAttrs: false,

    props: {
        /**
         * v-calendar attributes. Typically used to highlight multiple dates or ranges.
         * @see https://vcalendar.io/date-expressions.html
         */
        attributes: {
            type: Array,
            default: () => [
                {
                    highlight: {
                        color: 'blue',
                        fillMode: 'outline',
                    },
                    dates: new Date(),
                },
            ],
        },

        /**
         * Whether or not to show clear button in the input
         */
        clearable: {
            type: Boolean,
            default: false,
        },

        /**
         * Whether or not to display time selects in the calendar
         */
        dateTime: {
            type: Boolean,
            default: false,
        },

        /**
         * Whether or not to display only time select
         */
        onlyTime: {
            type: Boolean,
            default: false,
        },

        /**
         * Whether or not the calendar popup is disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Date format that is returned to the default slot (trigger input value)
         */
        displayFormat: {
            type: String,
            default: null,
        },

        /**
         * Whether or not to show calendar popup full-width
         */
        fullWidth: {
            type: Boolean,
            default: true,
        },

        /**
         * Whether or not the date is required. Setting to "true" will disable the possibility of deselecting the
         * currently selected date by clicking it again.
         */
        isRequired: {
            type: Boolean,
            default: false,
        },

        /**
         * Disable dates up until this date
         */
        minDate: {
            type: Date,
            default: undefined,
        },

        /**
         * Disable dates after this date
         */
        maxDate: {
            type: Date,
            default: undefined,
        },

        /**
         * Placeholder for trigger input
         */
        placeholder: {
            type: String,
            default: '',
        },

        /**
         * Placement of the popover
         * @see https://vcalendar.io/api/v2.0/datepicker.html#popover-placement
         */
        popoverPlacement: {
            type: String,
            default: undefined,
        },

        /**
         * Date format that is returned in @input
         */
        returnFormat: {
            type: String,
            default: null,
        },

        /**
         * Shows the week number on the left side of the calendar (Boolean)
         * or on a specified side of the calendar (left, left-outside, right, right-outside)
         */
        showWeeks: {
            type: null,
            default: false,
        },

        /**
         * Input value
         */
        modelValue: {
            type: null,
            required: true,
            default: () => null,
        },

        /**
         * Whether or not to enable range selection
         */
        range: {
            type: Boolean,
            default: false,
        },

        /**
         * Whether or not add piece of css that fixes jumping year popover
         */
        yearPopoverFix: {
            type: Boolean,
            default: false,
        },

        /**
         * Maximum range as days for date range picker
         */
        maximumRange: {
            type: Number,
            default: -1,
        },

        /**
         * If true, the datepicker will only show a calendar icon and not the input field
         */
        iconOnly: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:modelValue', 'popover-did-hide'],

    data: () => ({
        isActive: false,
        disabledDates: null,
        isFirstDaySelected: false,
        dateRangeOptions: [],
        defaultDateFormat: 'YYYY-MM-DD',
    }),

    computed: {
        // Datepicker component is 💩 and defaults to 2024 so we have to override it.
        // This can hopefully be deleted if/when the component is updated and this is fixed
        // Or if/when 🙏 we replace the entire thing
        initialPage() {
            // Default which is current month and year
            const current = {
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
            };

            // If a date is set and it's not a range object
            if (this.value !== null && !this.range) {
                return {
                    month: new Date(this.value).getMonth() + 1,
                    year: new Date(this.value).getFullYear(),
                };
            }

            // If a date is set and it's a range object
            if (this.value !== null && this.range) {
                // Return default if it's empty range object
                if (!this.value.start && !this.value.end) {
                    return current;
                }

                return {
                    month: new Date(this.value.start).getMonth() + 1,
                    year: new Date(this.value.start).getFullYear(),
                };
            }

            // If no value, set to default
            return current;
        },
        currentPeriodDateRange() {
            if (this.range && this.value && this.value.start && this.value.end) {
                const foundDateRangeOption = this.dateRangeOptions.find((option) => {
                    // add return format to all the components
                    // that use this component or define default return format
                    const valueStart = moment(this.value.start).format(this.defaultDateFormat);
                    const valueEnd = moment(this.value.end).format(this.defaultDateFormat);

                    return option.start === valueStart && option.end === valueEnd;
                });

                return foundDateRangeOption?.id ?? DateRangeOptionTypes.CUSTOM;
            }

            return DateRangeOptionTypes.CUSTOM;
        },
        attributesWithHolyDays() {
            const today = new Date();
            const threeYearsHolyDaysOfNorway = getHolyDays('NO', [
                today.getFullYear() - 1,
                today.getFullYear(),
                today.getFullYear() + 1,
            ]).map((d) => {
                return {
                    dot: 'red',
                    dates: d.startDate,
                    popover: {
                        label: d.name,
                        labelClass: 'holy-day-popover',
                    },
                };
            });

            return [...threeYearsHolyDaysOfNorway, ...this.attributes];
        },
        lang() {
            return document.documentElement.lang || 'nb';
        },
        mode() {
            if (this.onlyTime) {
                return 'time';
            } else if (this.dateTime) {
                return 'dateTime';
            } else {
                return 'date';
            }
        },
        displayedPlaceholder() {
            if (this.placeholder) {
                return this.placeholder;
            }

            return this.onlyTime ? this.trans('shared.Velg tid') : this.trans('shared.Velg dato');
        },
        value() {
            // Return Date of the selected date
            if (this.range) {
                return {
                    start: this.modelValue ? moment(this.modelValue.start, this.returnFormat).toDate() : null,
                    end: this.modelValue ? moment(this.modelValue.end, this.returnFormat).toDate() : null,
                };
            } else {
                return this.modelValue ? moment(this.modelValue, this.returnFormat).toDate() : null;
            }
        },
    },

    mounted() {
        this.dateRangeOptions = getDateRangeOptions(null, null, this.defaultDateFormat);
    },

    methods: {
        setRangeValue(value) {
            const foundDateRangeOption = this.dateRangeOptions.find(o => o.id === value);
            const start = moment(foundDateRangeOption?.start, this.defaultDateFormat);
            const end = moment(foundDateRangeOption?.end, this.defaultDateFormat);
            const datepicker = this.$refs.datepicker;

            if (this.returnFormat) {
                this.handleInput({
                    start,
                    end,
                });
            } else {
                // if there is no return format, return Javascript Date Format date like this:
                // "Wed Jan 17 2024 18:51:58 GMT+0300 (GMT+03:00)"
                this.handleInput({
                    start: start.toDate(),
                    end: end.toDate(),
                });
            }

            datepicker.move(start.toDate()); // focus start date when one of the preset selected
        },
        rangeValue(value) {
            return !value.start && !value.end ? null : `${value.start} – ${value.end}`;
        },
        handleDayClicked(day, event) {
            if (!this.isFirstDaySelected) {
                this.isFirstDaySelected = true;
                const startDate = this.$moment(day.id, 'YYYY-MM-DD')
                    .subtract(this.maximumRange, 'days')
                    .toDate();
                const endDate = this.$moment(day.id, 'YYYY-MM-DD')
                    .add(this.maximumRange, 'days')
                    .toDate();

                if (this.maximumRange > 0) {
                    this.disabledDates = [
                        {
                            start: null,
                            end: startDate,
                        },
                        {
                            start: endDate,
                            end: null,
                        },
                    ];
                }
            }
            event.target.blur();
        },
        handleInput(event) {
            let data = null;

            if (event) {
                if (this.range) {
                    data = {
                        start: moment(event.start).format(this.returnFormat),
                        end: moment(event.end).format(this.returnFormat),
                    };
                } else {
                    data = moment(event).format(this.returnFormat || this.defaultDateFormat);
                }
            }
            this.$emit('update:modelValue', data);
        },
        handlePopoverDidHide() {
            this.isActive = false;
            this.isFirstDaySelected = false;
            this.disabledDates = null;
            this.$emit('popover-did-hide');
        },
        openCalendarPopover(event, openPopover) {
            if (this.value === null && this.mode === 'time') {
                // time picker doesn't work with null value so we need to initate some value
                const temporaryDate = moment().format(this.returnFormat);
                this.$emit('update:modelValue', temporaryDate);
            }

            openPopover(event);
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-height: 1079px) {
    :deep(.vs__dropdown-menu) {
        max-height: 130px;
    }
}

.kk-datepicker:deep() {
    .vc-blue {
        // Override the theme's variables here
        --vc-font-family: var(--font-family-primary);
        --vc-accent-50: var(--kk-blue-50);
        --vc-accent-100: var(--kk-blue-100);
        --vc-accent-200: var(--kk-blue-200);
        --vc-accent-300: var(--kk-blue-300);
        --vc-accent-400: var(--kk-blue-400);
        --vc-accent-500: var(--kk-blue-500);
        --vc-accent-600: var(--kk-blue-600);
        --vc-accent-700: var(--kk-blue-700);
        --vc-accent-800: var(--kk-blue-800);
        --vc-accent-900: var(--kk-blue-900);
        --vc-shadow-lg: var(--box-shadow-md);
        --vc-rounded: var(--radius-xs);
        --vc-rounded-md: var(--radius-sm);
        --vc-rounded-lg: var(--radius-md);
        --vc-rounded-full: var(--radius-full);
        --vc-border: var(--border-color-primary);
        // Override colours for the selected dates & range
        --vc-highlight-light-bg: var(--vc-accent-100);
        --vc-highlight-solid-bg: var(--vc-accent-600);
    }

    .vc-header button, .vc-nav-container button:not(.is-active) {
        background-color: unset;

        &:hover {
            background-color: var(--gray-50);
        }
    }

    input[disabled] {
        cursor: not-allowed;
    }

    // As the component often has issues with placing the caret correctly, we just hide it for now
    // It may be worth revisiting this in the future as new versions/forks may fix this issue
    .vc-popover-caret {
        display: none;
    }

    .vc-popover-content-wrapper.is-interactive {
        --popover-vertical-content-offset: 10px;

        .vc-title {
            text-transform: capitalize;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0;
        padding: 5px 10px;
        flex-direction: column;
        gap: 20px;

        .select {
            margin-top: var(--spacing-0_5)
        }
    }

    .default-calendar-input {
        border: 1px solid rgba(60, 60, 60, 0.26);
        border-radius: 4px;
        background: none;
        height: 45px;
        padding: 0 10px;
        width: 100%;

        &[disabled] {
            background-color: var(--input-background-disabled);
            color: var(--text-disabled);
        }
    }

    .icon-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        border: 1px solid #979797;
        color: var(--text-primary);
        background-color: unset;
        padding: 8px;
        cursor: pointer;

        &:hover {
            background: var(--background-level-1);
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .input-wrapper {
        position: relative;

        input[disabled] {
            cursor: not-allowed;
        }

        .clear-button {
            box-sizing: content-box;
            color: rgba(60, 60, 60, 0.6);
            cursor: pointer;
            font-size: 16px;
            padding: 5px;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                color: rgba(60, 60, 60, 0.9);
            }

            &[disabled] {
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }

    &.jumping-year-fix {
        position: relative;
        .vc-popover-content-wrapper.is-interactive:has(> div > .vc-nav-container) {
            transform: translate(43px, 38px) !important;
        }

        .vc-popover-content-wrapper.is-interactive {
            transform: translate(0px, 38px) !important;
        }
    }

    // hides date above time selects
    .vc-time-header {
        display: none !important;
    }

    // time select styling fixes
    .vc-base-select {
        select.vc-focus {
            border: none;
            color: var(--text-primary);

            &:hover {
                background-color: var(--vc-nav-hover-bg)
            }
        }
    }

    // show arrows on iOS
    .vc-base-icon {
        min-width: 14px;
    }
}

.kk-datepicker.full-width:deep() {
    /* Hack to make popover expand parent's full width */
    .vc-container {
        min-width: 100%;
    }

    .vc-popover-content-wrapper.is-interactive,
    .vc-popover-content {
        width: 100%;
        z-index: 11 !important;
    }
}

</style>
