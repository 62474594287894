<template>
    <section class="kk-logic-filter-select">
        <div class="kk-logic-filter-select-head">
            <div
                v-if="showOperator"
                v-click-outside="handleOperatorSelect"
                class="operator"
                :class="{ 'operator-checkboxes-visible': operatorCheckboxesVisible }"
            >
                <div class="operator-dropdown" @click="operatorCheckboxesVisible = true">
                    <span class="operator-dropdown-title">
                        {{ trans('shared.Vis resultater som') }}

                        <fa-icon class="operator-dropdown-title-arrow" :icon="['fas', 'caret-down']" />
                    </span>
                    <span class="operator-dropdown-selected">
                        <span class="operator-dropdown-selected-key">{{
                            isContaining ? '' : trans('shared.Ikke')
                        }}</span>
                        {{ trans('shared.Inneholder(Containing)') }}
                        <span class="operator-dropdown-selected-key">{{
                            isAny ? trans('shared.Noen') : trans('shared.Alle')
                        }}</span>
                    </span>
                </div>

                <div class="operator-dropdown-checkboxes-container">
                    <div v-if="showContaining" class="group">
                        <kk-checkbox
                            size="small"
                            color="success"
                            :modelValue="isContaining"
                            @update:modelValue="changeContaining(true)"
                        >
                            {{ trans('shared.Inneholder(Containing)') }}
                        </kk-checkbox>
                        <kk-checkbox
                            size="small"
                            color="success"
                            :modelValue="!isContaining"
                            @update:modelValue="changeContaining(false)"
                        >
                            {{ trans('shared.Ikke inneholder') }}
                        </kk-checkbox>
                    </div>

                    <div class="group">
                        <kk-checkbox
                            size="small"
                            color="success"
                            :modelValue="isAny"
                            @update:modelValue="changeAny(true)"
                        >
                            {{ trans('shared.Noen') }}
                        </kk-checkbox>
                        <kk-checkbox
                            size="small"
                            color="success"
                            :modelValue="!isAny"
                            @update:modelValue="changeAny(false)"
                        >
                            {{ trans('shared.Alle') }}
                        </kk-checkbox>
                    </div>
                </div>
            </div>
        </div>

        <v-select
            :id="filterId"
            :modelValue="modelValue"
            :options="options"
            :reduce="reduce"
            :label="label"
            :placeholder="placeholder"
            :clearable="true"
            :multiple="multiple"
            :selectable="option => !modelValue?.includes(option.id)"
            @update:modelValue="$emit('change-select', $event)"
        />
    </section>
</template>

<script>

import vSelect from 'vue-select';
import kkCheckbox from '../kk-checkbox/kk-checkbox.vue';
import ClickOutside from 'click-outside-vue3';

// (OR) Containing & Any
// (AND) Containing & All
// (NOR) Not containing & All
// (NAND) Not containing & Any
const Operators = {
    AND: 'AND',
    OR: 'OR',
    NAND: 'NAND',
    NOR: 'NOR',
};

export default {
    emits: ['change-select', 'change-operator'],

    components: {
        vSelect,
        kkCheckbox,
    },

    directives: {
        ClickOutside: ClickOutside.directive,
    },

    props: {
        filterId: {
            type: String,
            default: '',
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        reduce: {
            type: Function,
            default: option => option.id,
        },
        label: {
            type: String,
            default: 'title',
        },
        operator: {
            type: String,
            default: '',
            validator: value => [Operators.AND, Operators.OR, Operators.NAND, Operators.NOR, ''].includes(value),
        },
        showOperator: {
            type: Boolean,
            default: false,
        },
        showContaining: {
            type: Boolean,
            default: true,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            operatorCheckboxesVisible: false,
            selectedOperator: '',
        };
    },

    computed: {
        isContaining() {
            return [Operators.OR, Operators.AND].includes(this.selectedOperator);
        },
        isAny() {
            return [Operators.OR, Operators.NAND].includes(this.selectedOperator);
        },
    },

    watch: {
        operator: {
            immediate: true,
            handler(value) {
                this.selectedOperator = value;
            },
        },
    },

    methods: {
        changeContaining(value) {
            if (this.isAny) {
                this.selectedOperator = value ? Operators.OR : Operators.NAND;
            } else {
                this.selectedOperator = value ? Operators.AND : Operators.NOR;
            }

            this.$emit('change-operator', this.selectedOperator);
        },
        changeAny(value) {
            if (this.isContaining) {
                this.selectedOperator = value ? Operators.OR : Operators.AND;
            } else {
                this.selectedOperator = value ? Operators.NAND : Operators.NOR;
            }

            this.$emit('change-operator', this.selectedOperator);
        },
        handleOperatorSelect() {
            if (this.operatorCheckboxesVisible) {
                this.operatorCheckboxesVisible = false;
                this.$emit('change-operator', this.selectedOperator);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/desktop.scss';

.v-select:deep() {
    .vs__dropdown-toggle {
        min-height: 40px;
    }
    .vs__dropdown-option--disabled {
        display: none;
    }
}
.kk-logic-filter-select {
    width: 100%;

    &-head {
        position: relative;
        display: flex;
    }

    .operator {
        display: flex;
        justify-content: flex-end;
        z-index: 2;
        position: relative;
        padding-bottom: 3px;
        background-color: transparent;
        box-shadow: none;
        margin-left: auto;
        width: 100%;

        &-dropdown {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            cursor: pointer;

            &-title {
                display: block;
                margin-bottom: 3px;

                &-arrow {
                    margin-left: 3px;
                }
            }

            &-selected {
                display: block;

                &-key {
                    font-weight: bold !important;
                }
            }

            &-checkboxes-container {
                position: absolute;
                top: 100%;
                right: 0;
                z-index: 3;
                background-color: white !important;
                padding: 0 8px 8px 8px;
                border-radius: 5px;
                display: none;
                box-shadow: 0 3px 10px rgb(0 0 0 / 15%) !important;
                width: max-content;
                border: 1px solid var(--border-color-level-1);

                .group {
                    padding-top: 10px;
                    display: none;
                    gap: 10px;
                    height: 0;

                    .kk-checkbox:deep() {
                        .box.selected {
                            /* removes the default checkmark*/
                            background-image: none;
                        }

                        label {
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        span {
            font-weight: normal;
            font-size: 12px;
            color: var(--text-primary);
        }
    }

    .operator-checkboxes-visible {
        .operator-dropdown-checkboxes-container {
            display: block !important;
        }

        .group {
            display: flex !important;
            height: auto !important;
        }
    }
}
</style>
