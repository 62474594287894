<template>
    <div :class="[$options.name, ...classes]">
        <div class="flex-wrapper">
            <span class="search">
                <fa-icon :icon="['fal', 'search']" class="search__icon" />
                <input
                    v-model="search"
                    type="text"
                    class="search__input"
                    :placeholder="trans('shared.Søk i kontakter')"
                    @keydown="search = $event.target.value"
                    @focus="onSearchFocus"
                >
                <kk-icon-button
                    v-if="search"
                    size="small"
                    :icon="['fal', 'close']"
                    @click="search = ''"
                />
            </span>
            <button v-if="addNew && canAddContacts" class="new-contact-button" @click="$emit('open-creation', true)">
                {{ trans('shared.Ny') }}
            </button>
        </div>
        <v-select
            v-model="selectedCategory"
            :class="{ 'category-filter': !mobile, 'category-filter-mobile': mobile }"
            :options="categories"
            label="name"
            :placeholder="trans('shared.Velg kategori')"
        />
        <ul class="list">
            <div
                v-for="contact in filteredContactOptions"
                :key="`${contact.category}_${contact.contactId}`"
                class="list__contact"
            >
                <button
                    :tabindex="hasContactInfo(contact) ? 0 : -1"
                    :class="[
                        'option',
                        'option__contact',
                        {
                            'option--selectable': hasContactInfo(contact)
                        }
                    ]"
                    :title="primary === 'email' ? contact.email : ''"
                    @click="selectOption($event, contact)"
                    @keydown.enter.space="selectOption($event, contact)"
                >
                    <span class="dotdotdot" :title="contact.name">{{ contact.name }}</span>
                    <span class="option__spanner" />
                    <kk-checkbox
                        v-if="hasContactInfo(contact)"
                        :size="mobile ? 'medium' : 'small'"
                        :model-value="isSelected(contact)"
                    />
                    <span
                        v-else-if="noContactTypes.includes(contact.id)"
                        class="no-contact-info"
                    />
                    <span
                        v-else
                        class="no-contact-info"
                        :title="trans('orders.Ingen kontaktinformasjon')"
                    >
                        {{ trans("orders.Ingen kontaktinformasjon") }}
                    </span>
                </button>
                <button
                    v-for="person in contact.persons"
                    :key="`${person.category}_${person.contactId}_${person.personId}`"
                    :class="[
                        'option',
                        'option__person',
                        {
                            'option--selectable': hasContactInfo(person)
                        }
                    ]"
                    :title="primary === 'email' ? person.email : ''"
                    @click="selectOption($event, person)"
                    @keydown.enter.space="selectOption($event, person)"
                >
                    <span class="dotdotdot" :title="person.name || person.full_name">
                        {{ person.name || person.full_name }}
                    </span>
                    <span class="option__spanner" />
                    <kk-checkbox
                        v-if="hasContactInfo(person)"
                        :model-value="isSelected(person)"
                        :size="mobile ? 'medium' : 'small'"
                    />
                    <span
                        v-else
                        class="no-contact-info"
                        :title="trans('orders.Ingen kontaktinformasjon')"
                    >
                        {{ trans("orders.Ingen kontaktinformasjon") }}
                    </span>
                </button>
                <button
                    v-if="addNew && canAddContacts && !noContactTypes.includes(contact.id)"
                    class="option option__person add-person-option"
                    @click="addContactPerson(contact)"
                >
                    {{ trans('contacts.Legg til kontaktperson') }}
                </button>
                <div class="list__divider" />
            </div>
        </ul>
    </div>
</template>

<script>

import { trans } from '../../../mixin/trans';
import kkCheckbox from '../../kk-checkbox/kk-checkbox.vue';
import kkIconButton from '../../kk-icon-button/kk-icon-button.vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {

    name: 'kk-contacts',

    components: {
        kkCheckbox,
        kkIconButton,
        vSelect,
    },

    mixins: [trans],

    props: {
        lastUsed: {
            type: Array,
            default() {
                return [];
            },
        },
        addNew: {
            type: Boolean,
            default: false,
        },
        categories: {
            type: Array,
            default: () => [],
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
        contactOptions: {
            type: Array,
            default: () => [],
        },
        contacts: {
            type: Array,
            default() {
                return [];
            },
        },
        primary: {
            type: String,
            default: 'email',
            validator: primary => ['email', 'mobile', 'none', 'either'].includes(primary),
        },
        max: {
            type: Number,
            default: 1,
            validator: (max) => {
                return max >= 1;
            },
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        classes: {
            type: Array,
            default: () => [],
        },
    },
    emits: [
        'open-creation',
        'update:modelValue',
        'new-mode-contact',
        'contact-person-mode',
    ],

    data() {
        return {
            search: '',
            selected: this.modelValue ? [...this.modelValue] : [],
            selectedCategory: this.lastUsed.length > 0 ? Lang.get('shared.Sist brukte') : '',
            langGetLastUsed: Lang.get('shared.Sist brukte'),
            noContactTypes: ['employees', 'teams', 'positions'],
        };
    },

    computed: {
        filteredContactOptions() {
            if (
                this.selectedCategory && (
                    typeof this.selectedCategory === 'string' ? this.selectedCategory : this.selectedCategory.name
                ).toUpperCase() === this.langGetLastUsed.toUpperCase()
            ) {
                return this.lastUsedOptions;
            }

            const _searchHitContact = person =>
                !this.search
                || this.contacts
                    .find(contact => contact.id === person.contactId)
                    .name.toUpperCase()
                    .includes(this.search.toUpperCase());

            const _searchHitPerson = contact =>
                !this.search
                || contact.persons
                    .some(person => person.name.toUpperCase()
                        .includes(this.search.toUpperCase()));

            const _searchHit = option =>
                !this.search
                || option.name.toUpperCase()
                    .includes(this.search.toUpperCase());

            const _categoryHit = ({ categories }) =>
                !this.selectedCategory || !categories
                || categories.find((key) => {
                    return key.toUpperCase() === (
                        typeof this.selectedCategory === 'string' ? this.selectedCategory : this.selectedCategory.name
                    ).toUpperCase();
                });

            return this.contactOptions
                .filter(contact => _categoryHit(contact) && (_searchHit(contact) || _searchHitPerson(contact)))
                .map((contact) => {
                    return {
                        ...contact,
                        persons: contact.persons.filter(person =>
                            _categoryHit(person) && (_searchHit(person) || _searchHitContact(person)),
                        ),
                    };
                }).sort((a, b) => !!b.sortFirst - !!a.sortFirst);
        },
        lastUsedOptions() {
            const _isSelected = (option) => {
                return this.selected.some(selected => this.equal(selected, option));
            };

            return [
                ...this.contactOptions.filter((contact) => {
                    return _isSelected(contact) || contact.persons.some(person => _isSelected(person));
                }),
                ...this.lastUsed.map((contact) => {
                    return {
                        ...contact,
                        personId: null,
                        contactId: contact.id,
                        category: this.langGetLastUsed,
                        persons: contact.persons.map((person) => {
                            return {
                                ...person,
                                name: person.name || person.full_name,
                                personId: person.id,
                                contactId: contact.id,
                                category: this.langGetLastUsed,
                            };
                        })
                            .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())),
                    };
                })
                    .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
                    .filter(contact => !_isSelected(contact) && contact.persons.every(person => !_isSelected(person))),
            ];
        },
        canAddContacts() {
            // Checks if user has ANY of the roles.
            // if not, the user is handled as an ordinary user, and can't edit contacts.
            return this.$store.state.user.roles.admin
                || this.$store.state.user.roles.projectManager
                || this.$store.state.user.company.settings?.ordersModuleAccessForBasicUsers
                || this.$store.state.user.permissions.order.canWrite;
        },
    },

    watch: {
        selectedCategory() {
            this.search = '';
        },
        modelValue: {
            deep: true,

            handler(value) {
                this.selected = value;
            },
        },
    },

    methods: {
        hasContactInfo(option) {
            if (this.primary === 'none') {
                return true;
            }

            if (this.primary === 'either') {
                return !!(option?.email?.length || option?.mobile?.length || option?.phone?.length);
            }

            if (!Object.prototype.hasOwnProperty.call(option, this.primary)) {
                return false;
            }

            if (this.primary === 'email') {
                return !!option?.email?.length;
            }

            if (this.primary === 'mobile') {
                return !!option?.mobile?.length || option?.phone?.length;
            }
        },
        selectOption(_, option) {
            // If unselectable
            if (!this.hasContactInfo(option)) {
                return;
            }

            // Special case
            if (this.max === 1) {
                if (this.isSelected(option)) {
                    this.selected = [];
                    this.emitInput();
                } else {
                    this.selected = [option];
                    this.emitInput();
                }

                return;
            }

            // General case
            if (this.isSelected(option)) {
                let foundIndex = this.selected.findIndex(selected => this.equal(selected, option));
                this.selected.splice(foundIndex, 1);
                this.emitInput();
            } else if (this.selected.length < this.max) {
                this.selected.push(option);
                this.emitInput();
            }
        },
        emitInput() {
            this.$emit('update:modelValue', this.selected.map((selected) => {
                let contact = this.contacts.find(c => c.id === selected.contactId);

                return {
                    personId: selected.personId,
                    contactId: selected.contactId,
                    name: selected.name,
                    email: selected.email,
                    mobile: selected.mobile,
                    type: selected.type,
                    contactName: contact ? (contact.full_name ? contact.full_name : contact.name) : '',
                    recipients: selected.recipients ? selected.recipients : '',
                };
            }));
        },
        onSearchFocus() {
            if (this.selectedCategory === this.langGetLastUsed) {
                this.selectedCategory = '';
            }
        },
        equal(optionA, optionB) {
            return optionA.contactId === optionB.contactId && optionA.personId === optionB.personId;
        },
        isSelected(option) {
            return this.selected.some(selected => this.equal(selected, option));
        },
        addContactPerson(contact) {
            this.$emit('new-mode-contact', contact);
            this.$emit('contact-person-mode', true);
            this.$emit('open-creation', true);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/dotdotdot.scss";
@import "../../../sass/unset.scss";
@import "../../../sass/variables.scss";

$selectGrey: #d9e3e5;
$dividerGrey: #d6d5d5;

.kk-contacts {

    .v-select:deep() {
        .vs__dropdown-menu {
            border-radius: 0 !important;
        }
    }
    &.mobile {
        .option {flex: 0 0 35px;}
        .kk-checkbox { padding: 0; }
        input,select, option, .option {font-size: 16px;}
        .mobile {
            width: 100%;
            height: 100%;
            max-height: 60vh;
        }
    }
    &.desktop {
        .option {flex: 0 0 23px;}
        .desktop {
            width: 280px;
            max-height: 450px;
            min-height: 400px;
            padding-bottom: 0;
        }
    }
    .flex-wrapper {
        display: flex;
        margin: 0 0 10px 0;
        min-height: 35px;
    }
    .category-filter {
        z-index: 9999 !important;
        margin-bottom: 6px;
        :deep(.vs__dropdown-toggle) {
            border-radius: 3px;
            height: 35px;
            color: #767676;
            align-items: center;
            border: none;
            background-color: var(--gray-100);
            input {
                color: var(--text-primary);
                padding: 0;
                padding: 5px 0 5px 8px;
                font-size: 12px;
                &::placeholder {
                    color: var(--text-primary) !important;
                }
            }
        }
    }
    .category-filter-mobile {
        z-index: 2 !important;
        margin-bottom: 6px;
        :deep(.vs__dropdown-toggle) {
            border-radius: 3px;
            height: 35px;
            color: #767676;
            align-items: center;
            border: none;
            background-color: var(--gray-100);
            input {
                color: var(--gray-500);
                padding: 0;
                padding: 5px 0 5px 10px;
                font-size: 16px;
                &::placeholder {
                    color: #767676 !important;
                }
            }
        }
    }
    .new-contact-button {
        background: #0aaef3;
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
        margin-left: 10px;
        border-radius: 3px;
        cursor: pointer;
    }
    .search {
        border-radius: 3px;
        flex: 1;
        height: 35px;
        display: flex;
        align-items: center;
        background-color: var(--gray-100);

        &__input {
            color: var(--gray-500);
            padding: 0;
            padding: 5px 0 5px 10px;
            width: 100%;
            flex: 1;

            &::placeholder {
                color: #767676 !important;
            }

            // below needed to prevent some evil css from_various.scss
            height: 35px;
            box-shadow: none;
            &:focus,
            &:active {
                border: none;
                box-shadow: none;
            }
        }

        &__icon {
            margin: 0 0 0 var(--spacing-1_25);

            :deep(svg) {
                color: var(--gray-500);
            }
        }
    }
    .list {
        overflow: auto;

        &__contact {
            display: flex;
            flex-direction: column;
        }
        &__divider {
            border: 0.5px solid $dividerGrey;
            margin: 10px 0;
        }

        /** --- scrollbar --- */
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: var(--gray-100);
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: var(--primary);
        }
        /** --- scrollbar --- */

        .option {
            border-radius: 3px;
            padding: 0 0 0 6px;
            display: flex;
            align-items: center;
            color: var(--gray-500);

            &--selectable:hover,
            &--selectable:focus {
                cursor: pointer;
                background-color: var(--gray-100);
            }

            &__spanner {
                flex: 1;
            }
            &__contact {
                font-weight: 600;
                margin: 0 10px 0 0;
            }
            &__person {
                margin: 0 10px;
            }
            .kk-checkbox {
                margin: 0 5px 0 0;
            }
            .no-contact-info {
                color: var(--text-disabled);
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: right;
            }
        }
        .add-person-option {
            color: var(--link-primary);

            &:hover,
            &:focus {
                cursor: pointer;
                text-decoration: underline;
                color: var(--link-primary-hover);
            }
        }
    }
}

</style>
