import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faCommentAlt } from '@fortawesome/pro-light-svg-icons/faCommentAlt';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';

import { touchstop, mousestop } from '../../directive/stop-propagation.js';
import { trans } from '../../mixin/trans.js';
import { filenameFromDate } from '../../lib/filename-from-date.js';
import ImageFunctions from '../../lib/image-functions.js';
import CanvasFunctions from '../../lib/canvas-functions.js';

library.add(faUndo, faCheck, faSpinner, faPencil, faCommentAlt);

// @vue/component
export default {
    name: 'kk-paint',
    components: {
        FontAwesomeIcon,
    },
    directives: {
        touchstop,
        mousestop,
    },
    mixins: [
        trans,
    ],
    colors: Object.freeze({
        Green: { name: 'colors.Grønn', rgb: '#39d17a' },
        Blue: { name: 'colors.Blå', rgb: '#3797da' },
        Red: { name: 'colors.Rød', rgb: '#e64d3e' },
        Yellow: { name: 'colors.Gul', rgb: '#f1c215' },
        Darkgrey: { name: 'colors.Mørgegrå', rgb: '#304051' },
        White: { name: 'colors.Hvit', rgb: '#ebf0f1' },
    }),
    props: {
        /**
         * dataURL with mediatype 'image/*'
         * Format: data:[<mediatype>][;base64],<data>
         */
        modelValue: {
            type: String,
            default: null,
        },

        /**
         * Enables if user is able to comment on the picture
         */
        enableComment: {
            type: Boolean,
            default: false,
        },

        /**
         * CSS classes (css specializations)
         */
        classes: {
            type: Array,
            default: () => ['mobile'],
            validator: classes => classes.every(_class => ['mobile', 'desktop'].includes(_class)),
        },
    },
    data() {
        return {
            touches: new Set(),
            penSize: 6,
            penColor: this.$options.colors.Red.rgb,
            lines: [],
            exifobj: null,

            canvasWidth: 0,
            canvasHeight: 0,
            windowWidth: 0,
            windowHeight: 0,

            mouseDown: false,
            uiVisible: true,
            commentMode: false,
            drawMode: false,
        };
    },
    computed: {
        noLines() {
            return this.lines.length === 0;
        },
        currentLineIndex() {
            return this.lines.length - 1;
        },
        currentLine() {
            if (this.noLines) {
                return null;
            }

            return this.lines[this.currentLineIndex];
        },
        canvasAspectRatio() {
            return this.canvasWidth / this.canvasHeight;
        },
        windowAspectRatio() {
            return this.windowWidth / this.windowHeight;
        },
        shouldClampWidth() {
            return this.canvasAspectRatio <= this.windowAspectRatio;
        },
        shouldClampHeight() {
            return this.canvasAspectRatio > this.windowAspectRatio;
        },
        isCommentMode() {
            return this.commentMode;
        },
        isDrawMode() {
            return this.drawMode;
        },
    },
    watch: {
        modelValue() {
            if (!this.modelValue) {
                document.body.style.overflow = 'visible';

                return;
                // ... hide popup
            }

            const maxTabletWidth = 1024;

            if (window.innerWidth <= maxTabletWidth) {
                document.body.style.overflow = 'hidden';
            }

            // Reset state
            this.mouseDown = false;
            this.touchDown = false;
            this.penSize = 6.5;
            this.penColor = this.$options.colors.Red.rgb;
            this.lines = [];
            this.commentMode = false;
            this.drawMode = false;
            CanvasFunctions.clear(this.$refs.backCanvas);
            CanvasFunctions.clear(this.$refs.frontCanvas);

            if (this.enableComment) {
                this.$refs.comment.innerText = '';
            }

            (async () => {
                // Render new image
                let dataURL = this.modelValue;

                let imgPromise = ImageFunctions.dataURLToImg(dataURL);
                let exifobj = ImageFunctions.getExifObj(dataURL);
                let exifOrientation = 0;
                let img = await imgPromise;

                await ImageFunctions.imgToCanvas(img, this.$refs.backCanvas, { exifOrientation });

                CanvasFunctions.copy(this.$refs.frontCanvas, this.$refs.backCanvas);

                ImageFunctions.deleteExifOrientation(exifobj);
                this.exifobj = exifobj;

                this.canvasHeight = this.$refs.frontCanvas.height;
                this.canvasWidth = this.$refs.frontCanvas.width;
                this.windowHeight = window.innerHeight;
                this.windowWidth = window.innerWidth;
            })();
        },
    },
    created() {
        let self = this;

        this.resizeEventListener = () => {
            self.windowWidth = window.innerWidth;
            self.windowHeight = window.innerHeight;
        };
        window.addEventListener('resize', this.resizeEventListener);
    },

    unmounted() {
        window.removeEventListener('resize', this.resizeEventListener);
    },

    methods: {
        /* ------------------------ EMITTERS -------------------------- */

        /**
         *
         * @param {String} dataURL base64
         * @param {Blob} blob with .name and .lastModifiedDate
         * @param {String} comment belongs to image
         */
        emitInput(dataURL, blob, comment = '') {
            this.$emit('update:modelValue', {
                dataURL,
                blob,
                comment,
            });
        },

        /* ----------------------EVENT HANDLERS -------------------------- */

        onClickSave() {
            (async () => {
                let dataURL = await ImageFunctions.canvasToDataURL(this.$refs.frontCanvas, { exifobj: this.exifobj });

                let blob = await ImageFunctions.dataURLToBlob(dataURL);

                let lastModifiedDate = new Date();
                blob.lastModifiedDate = lastModifiedDate;

                let filename = filenameFromDate(lastModifiedDate);
                blob.name = filename;

                if (this.enableComment) {
                    var comment = this.$refs.comment.innerText.trim();
                }

                this.emitInput(dataURL, blob, comment);
            })();
        },

        onClickUndo() {
            if (this.lines.length === 0) {
                return;
            }
            CanvasFunctions.copy(this.$refs.frontCanvas, this.$refs.backCanvas);
            this.lines.pop();
            CanvasFunctions.drawLines(this.$refs.frontCanvas, this.lines);
        },

        onClickColor(event) {
            this.penColor = event.target.getAttribute('data-color');
        },

        onClickDraw() {
            if (this.drawMode) {
                this.drawMode = false;
            } else {
                this.mouseDown = false;
                this.drawMode = true;
            }
        },
        onClickComment() {
            if (this.enableComment) {
                this.commentMode = this.commentMode ? false : true;

                if (this.$refs.comment.innerHTML.trim() !== '') {
                    return;
                }
                this.$refs.comment.innerHTML = this.trans('shared.Skriv kommentar');
            }
        },
        onFocusComment(event) {
            let el = event.target;

            if (el.innerText.trim() == this.trans('shared.Skriv kommentar')) {
                el.innerHTML = '';
            }
        },
        //
        // ------------------------- MOUSE ---------------------------------
        //
        onMouseDown(event) {
            if (event.target.closest('.colors__btn') || event.target.closest('.buttons__btn')) {
                return;
            }

            this.uiVisible = false;

            if (!this.drawMode) {
                return;
            }
            this.mouseDown = true;
            let localPositon = CanvasFunctions.getLocalPosition(this.$refs.frontCanvas, event);
            let line = CanvasFunctions.beginLine(this.$refs.frontCanvas, localPositon, this.penColor, this.penSize);
            this.lines.push(line);
        },

        onMouseMove(event) {
            if (!this.mouseDown) {
                return;
            }
            let localPositon = CanvasFunctions.getLocalPosition(this.$refs.frontCanvas, event);
            CanvasFunctions.moveLine(this.$refs.frontCanvas, this.currentLine, localPositon);
        },

        onMouseEnter(event) {
            if (event.buttons) {
                this.onMouseDown(event);
            }
        },

        onMouseCancel() {
            this.uiVisible = true;
            this.mouseDown = false;
        },

        //
        // -------------------------- TOUCH ----------------------------------
        //
        onTouchStart(event) {
            if (event.target.closest('.colors__btn') || event.target.closest('.buttons__btn')) {
                return;
            }
            this.uiVisible = false;

            if (!this.drawMode) {
                return;
            }

            for (let touch of event.changedTouches) {
                this.touches.add(touch.identifier);
            }

            if (this.touches.size > 1) {
                return;
            }

            let touch = event.changedTouches[0];
            let localPosition = CanvasFunctions.getLocalPosition(this.$refs.frontCanvas, touch);
            let line = CanvasFunctions.beginLine(this.$refs.frontCanvas, localPosition, this.penColor, this.penSize);
            this.lines.push(line);
        },

        onTouchMove(event) {
            if (!this.drawMode) {
                return;
            }

            if (this.touches.size > 1) {
                return;
            }

            let touch = event.changedTouches[0];

            if (!this.touches.has(touch.identifier)) {
                return;
            }
            event.preventDefault();

            let localPositon = CanvasFunctions.getLocalPosition(this.$refs.frontCanvas, touch);
            CanvasFunctions.moveLine(this.$refs.frontCanvas, this.currentLine, localPositon);
        },

        onTouchCancel() {
            this.uiVisible = true;
            this.touches = new Set();
        },
    },
};
