<template>
    <!-- Settings (shows if no customer/vendor number is set) -->
    <kk-modal-v2
        class="settings-modal"
        type="separated"
        @hide="closeNumberSettings"
        @ok="saveNumberSettings()"
    >
        <template #title>
            {{ trans('shared.Innstillinger') }}
        </template>
        <template #content>
            <div v-if="settings">
                <p v-if="settingsError" class="warning">
                    <fa-icon :icon="['fal', 'exclamation-triangle']" />
                    {{ trans('contacts.Innstilling for første kunde- eller leverandørnummer er ikke satt. Du kan sette første nummer i nummerserien her.') }}
                </p>
                <label for="first-customer-number">
                    {{ trans('contacts.Første kundenummer') }}
                    <input
                        id="first-customer-number"
                        v-model.number="first_customer_number"
                        type="number"
                        min="1"
                        step="1"
                        :disabled="customerNumberDisabled ? true : null"
                        @input="first_customer_number = validateNumber(first_customer_number)"
                    >
                </label>
                <label for="first-vendor-number">
                    {{ trans('contacts.Første leverandørnummer') }}
                    <input
                        id="first-vendor-number"
                        v-model.number="first_vendor_number"
                        type="number"
                        min="1"
                        step="1"
                        :disabled="vendorNumberDisabled ? true : null"
                        @input="first_vendor_number = validateNumber(first_vendor_number)"
                    >
                </label>
                <small>{{ trans('contacts.Det er ikke mulig å endre innstillingen for første kunde- eller leverandørnummer etter at de er satt.') }}</small>
            </div>
        </template>
    </kk-modal-v2>
</template>

<script>
import kkModalV2 from '../../kk-modal-v2/kk-modal-v2.vue';
import ks from '../../../lib/ks';
import { trans } from '../../../mixin/trans';

import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    emits: ['settings-status'],
    name: 'customer-vendor-settings',

    components: {
        kkModalV2,
    },

    mixins: [trans],

    props: {
        settingsOpen: {
            type: Boolean,
            default: false,
        },
        settingsError: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState({
            settings: state => state.contactsStore.settings,
            customerNumberDisabled: state => state.contactsStore.customerNumberDisabled,
            vendorNumberDisabled: state => state.contactsStore.vendorNumberDisabled,
        }),
        first_customer_number: {
            get() {
                return this.settings['contact.first_customer_number'];
            },
            set(value) {
                this.UPDATE_CUSTOMER_NUMBER(this.validateNumber(value));
            },
        },
        first_vendor_number: {
            get() {
                return this.settings['contact.first_vendor_number'];
            },
            set(value) {
                this.UPDATE_VENDOR_NUMBER(this.validateNumber(value));
            },
        },
    },

    created() {
        this.fetchSettings();
    },

    methods: {
        ...mapActions('contactsStore', [
            'fetchSettings',
            'saveSettings',
        ]),
        ...mapMutations('contactsStore', [
            'UPDATE_CUSTOMER_NUMBER',
            'UPDATE_VENDOR_NUMBER',
            'SET_CUSTOMER_NUMBER_DISABLED',
            'SET_VENDOR_NUMBER_DISABLED',
        ]),
        async saveNumberSettings() {
            if (this.customerNumberDisabled && this.vendorNumberDisabled) {
                ks.alert(
                    Lang.get('errors.Feil'),
                    Lang.get('contacts.Det er ikke mulig å endre innstillingen for første kunde- eller leverandørnummer etter at de er satt.'),
                );
            } else {
                await this.saveSettings();
                this.$emit('settings-status', false);
            }
        },
        closeNumberSettings() {
            if (!this.customerNumberDisabled) {
                this.UPDATE_CUSTOMER_NUMBER(null);
                this.SET_CUSTOMER_NUMBER_DISABLED(false);
            }

            if (!this.vendorNumberDisabled) {
                this.UPDATE_VENDOR_NUMBER(null);
                this.SET_VENDOR_NUMBER_DISABLED(false);
            }
            this.$emit('settings-status', false);
        },
        validateNumber(num) {
            return num === '' ? null : Math.abs(Math.trunc(num));
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/variables.scss";

.settings-modal {
    h2 {
        margin: unset;
    }
    label {
        display:block;
        margin-bottom: 15px;
        input{
            margin-top: 6px;
            &:disabled {
                background: #ddd;
            }
        }
    }
    .dialogbox {
        max-width: 530px;
    }
    .warning {
        background-color: #ffcf00;
        box-shadow: 0 0 2px -1px #000;
        border-radius: 5px;
        padding: 10px;
        color: var(--color-dark-text);
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.2;
        svg {
            margin-right: 3px;
        }
    }
}
input {
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    border: 1px solid #D6D5D5;
    border-radius: 3px;
    background-color: #FFFFFF;
    font-family: "Work Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0.15px;
    padding-left: 10px;
}
</style>
