<template>
    <div v-click-outside="close" class="kk-dropdown-wrapper" :class="{ dense }">
        <section :class="['kk-dropdown', { open }]" @click="open = !open">
            <slot name="trigger-button">
                <button class="dropdown-header">
                    <slot name="trigger-button-image" />
                    {{ placeholder }}
                </button>
            </slot>
        </section>
        <transition name="fade">
            <section v-if="open" class="dropdown-list" @click.stop="closeSelf">
                <slot default />
            </section>
        </transition>
    </div>
</template>

<script>

import ClickOutside from 'click-outside-vue3';

export default {
    name: 'kk-dropdown',

    directives: {
        ClickOutside: ClickOutside.directive,
    },

    props: {
        placeholder: {
            type: String,
            default: '',
        },
        dense: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            open: false,
        };
    },

    mounted() {
        // prevent click outside event with popupItem.
        this.popupItem = this.$el;
    },

    methods: {
        close() {
            this.open = false;
        },
        closeSelf() {
            if (!this.persistent) {
                this.open = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';

.kk-dropdown-wrapper {
    position: relative;
    display: inline-block;
    touch-action: manipulation;
    user-select: none;
    .kk-dropdown {
        position: relative;
        height: 100%;
        &.open {
            .dropdown-header {
                background-color: #fff;
                border-bottom-color: transparent;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                &::after {
                    transform: rotate(-180deg);
                }
            }
        }
        .dropdown-header {
            width: 140px;
            height: 42px;
            padding: 6px 20px 6px 12px;
            cursor: pointer;
            white-space: nowrap;
            font-size: 14px;
            text-align: left;
            background-color: #ecf0f3;
            color: #333;
            border-radius: 4px;
            border: 1px solid #a0a0a0;
            transition: all 200ms linear;

            &:hover {
                background-color: rgba($primary, 10%) !important;
            }

            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-45%);
                right: 8px;
                width: 7px;
                height: 6px;
                background: url('/images/ico-arrow-small.png') no-repeat 0 0;
                margin-top: -3px;
                content: "";
                transition: all 200ms linear;
                transform-origin: 50% 25%;
            }
        }
    }

    .dropdown-list:deep() {
        position: absolute;
        z-index: 1000;
        min-width: 100%;
        max-width: 300px;
        top: 37px;
        right: 0;
        max-height: 200px;
        overflow: auto;
        margin-top: -1px;
        font-size: 14px;
        text-align: left;
        background-color: #fff;
        border-width: 1px;
        border-style: solid;
        border-color: #a0a0a0;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        > * {
            display: block;
            width: 100%;
            height: 42px;
            color: #333333;
            line-height: 42px;
            text-align: left;
            background-color: transparent;
            padding: 0 12px;
            border: 0;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-decoration: none;
            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &:hover, &:focus {
                background-color: #ecf0f3;
            }
        }
    }

    &.dense {
        .dropdown-list:deep() {
            padding: 5px 0;
            top: initial;
            margin-top: 0;
            color: inherit;
            > * {
                line-height: 20px;
                height: 20px;
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 250ms;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
}

</style>
