<template>
    <section
        v-if="hasBorder"
        :class="[
            'kk-card',
            border.predicate ? border.type : false,
            disableBackground ? false : `bg-${type}`,
            shouldDisableBoxShadow ? false : 'bg-shadow',
        ]"
    >
        <span
            v-if="hasBorder && border.title"
            class="border-title"
        >
            {{ border.title }}
        </span>
        <section
            v-if="title"
            :style="[
                cardSpacing('margin', margin),
            ]"
        >
            <section class="card-header">
                {{ title }}
            </section>
            <section
                class="card-content"
                :style="[cardSpacing('padding', padding)]"
            >
                <slot />
            </section>
            <div v-show="loading" class="loading-overlay">
                <kk-loader />
            </div>
        </section>

        <section
            v-else
            :style="[
                cardSpacing('margin', margin),
                cardSpacing('padding', padding),
            ]"
        >
            <slot />
            <div v-show="loading" :class="['loading-overlay', `${type}`]">
                <kk-loader />
            </div>
        </section>
    </section>

    <section
        v-else-if="title"
        :class="[
            'kk-card',
            disableBackground ? false : `bg-${type}`,
            shouldDisableBoxShadow ? false : 'bg-shadow',
        ]"
        :style="[
            cardSpacing('margin', margin),
        ]"
    >
        <section class="card-header">
            {{ title }}
        </section>
        <section
            class="card-content"
            :style="[cardSpacing('padding', padding)]"
        >
            <slot />
        </section>
        <div v-show="loading" class="loading-overlay">
            <kk-loader />
        </div>
    </section>

    <section
        v-else
        :class="[
            'kk-card',
            disableBackground ? false : `bg-${type}`,
            shouldDisableBoxShadow ? false : 'bg-shadow',
        ]"
        :style="[
            cardSpacing('margin', margin),
            cardSpacing('padding', padding),
        ]"
    >
        <slot />
        <div v-show="loading" :class="['loading-overlay', `${type}`]">
            <kk-loader />
        </div>
    </section>
</template>

<script>

import kkLoader from '../kk-loader/kk-loader.vue';

export default {
    name: 'kk-card',

    components: {
        kkLoader,
    },

    props: {
        padding: {
            type: [Number, Array],
            default: 20,
        },
        margin: {
            type: [Number, Array],
            default: 20,
        },
        type: {
            type: String,
            default: 'default',
            validator: (value) => {
                return [
                    'default',
                    'primary',
                    'success',
                    'warning',
                    'danger',
                ].includes(value);
            },
        },
        disableShadow: {
            type: Boolean,
            default: false,
        },
        disableBackground: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * Adds a border around the component, only showing if the predicate is true, with an optional title
         * Example showing a border if the form is completed:
         * {
         *      type: 'success',
         *      title: trans('shared.Fullført den :date', { date: moment(form.completed_at).format('DD.MM.YYYY') }),
         *      predicate: form.completed_at != null
         * }
         */
        border: {
            type: Object,
            default: () => ({ type: 'none' }),
            validator: (value) => {
                return value.type == 'none'
                    || (
                        Object.prototype.hasOwnProperty.call(value, 'type')
                        && Object.prototype.hasOwnProperty.call(value, 'predicate')
                    );
            },
        },
    },

    computed: {
        shouldDisableBoxShadow() {
            if (this.hasBorder) {
                return true;
            }

            return this.disableShadow;
        },
        hasBorder() {
            return this.border.type !== 'none' && this.border.predicate;
        },
    },

    methods: {
        // Takes a number or an array of numbers and appends px to the values
        cardSpacing(spacingType, prop) {
            let spacing = '';

            if (typeof prop === 'number') {
                spacing = `${prop}px`;
            } else if (Array.isArray(prop)) {
                prop.forEach((item) => {
                    spacing += `${item}px `;
                });
            }

            return { [spacingType]: spacing };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';

$card-border-radius: var(--radius-primary);
$backgrounds: ('default': #fff, 'primary': $primary, 'success': $success, 'warning': $warning, 'danger': $danger);

.kk-card {
    position: relative;
    border-radius: $card-border-radius;

    &.bg {
        &-shadow {
            box-shadow: var(--box-shadow-sm);
        }

        @each $type, $color in $backgrounds {
            /* Creates .bg-default, .bg-primary and so on */
            &-#{$type} {
                background-color: $color;
            }
        }
    }

    & > section {
        width: 100%;
        height: 100%;
    }

    &.success {
        border :3px solid var(--color-success);

        & > .border-title {
            background: var(--color-success);
            color: white;
        }
    }

    &.warning {
        border :3px solid var(--color-warning);

        & > .border-title {
            background: var(--color-warning);
            color: white;
        }
    }

    &.danger {
        border :3px solid var(--color-danger);

        & > .border-title {
            background: var(--color-danger);
            color: white;
        }
    }

    .border-title {
        position: absolute;
        left: 0;
        right: 0;
        top: -9px;

        height: 16px;
        width: fit-content;
        padding: 0 15px;
        margin: auto;

        font-size: 11px;
        text-align: center;
        border-radius: 9px;
    }

    .card-header {
        padding: 20px;
        font-size: 16px;
        font-weight: normal;
        border-bottom: solid 1px $lightGrey;
        color: var(--text-primary);
        font-family: 'Work Sans', sans-serif;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @each $type, $color in $backgrounds {
            &.#{$type} {
                background-color: rgba($color, 0.6);
            }
        }

        .kk-loader {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}
</style>
