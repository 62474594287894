<template>
    <span ref="svg" :class="cssClasses" v-html="svg.content" />
</template>

<script>
import { nextTick } from 'vue';

/**
 * SVG Icon component. Reads SVG icons from given directory and injects into DOM.
 * Supports setting width, height, viewBox, and has nifty functions for trimming whitespace,
 * animating it to spin, flip it etc.
 *
 * @author Alexander Karlstad <alexander@kvalitetskontroll.no>
 * @see https://github.com/cenkai88/vue-svg-icon
 * @see http://calebporzio.com/using-inline-svgs-in-vue-compoments/
 */
export default {
    name: 'svg-icon',

    props: {
        // Set CSS classes
        css: {
            type: [String, Array, Object],
            default() {
                return {};
            },
        },
        // Set or override SVG element's fill colour
        fill: {
            type: String,
            default: null,
        },
        // Set flip to horizontal or vertical. This will in turn set a css class that actually flips the SVG
        flip: {
            type: String,
            default: null,
            validator(val) {
                return val === 'horizontal' || val === 'vertical';
            },
        },
        // Set SVG height
        height: {
            type: [String, Number],
            default: null,
        },
        // Set name of the SVG icon to use, _without_ suffix (.svg)
        name: {
            type: String,
            required: true,
        },
        // Set whether or not to add the spin CSS class to SVG
        spin: {
            type: Boolean,
            default: false,
        },
        // Set/override stroke colour on SVG element
        stroke: {
            type: String,
            default: null,
        },
        // Set/override stroke colour on all SVG element's children
        strokeAll: {
            type: String,
            default: null,
        },
        strokeWidth: {
            type: String,
            default: null,
        },
        /**
         * Trim whitespace of a SVG by setting the viewBox to where the content starts.
         *
         * @see https://gist.github.com/john-doherty/2ad94360771902b16f459f590b833d44
         */
        trim: {
            type: Boolean,
            default: false,
        },
        // Manually set/override viewBox
        viewBox: {
            type: String,
            default: null,
        },
        // Set SVG width
        width: {
            type: [String, Number],
            default: null,
        },
    },

    data() {
        return {
            cssClasses: {},
            svg: {
                content: '',
            },
        };
    },

    watch: {
        name() {
            this.loadEverything();
        },
    },

    mounted() {
        this.loadEverything();
    },

    methods: {
        async loadEverything() {
            // NOTE: We need to handle subdirectories manually, as vite doesn't support it.
            // See: https://vitejs.dev/guide/features#dynamic-import
            if (this.name.indexOf('weather/') === 0) {
                let name = this.name.replace('weather/', '');
                this.svg.content = (await import(`../../svg/weather/${name}.svg?raw`)).default;
            } else {
                this.svg.content = (await import(`../../svg/${this.name}.svg?raw`)).default;
            }

            // Wait for DOM update before checking for <svg> element and do changes if we need.
            // This works a lot better in Vue 2.6 than setTimeout(), especially on pages where a lot
            // of data is loaded async with ajax, delaying the rendering.
            nextTick(() => {
                if (!this.$refs.svg) {
                    return;
                }

                let svgElm = this.$refs.svg.querySelector('svg');

                if (typeof svgElm === 'undefined' || !svgElm) {
                    return;
                }

                // Get proper viewBox value
                let viewBox = this.viewBox !== null ? this.viewBox : svgElm.getAttribute('viewBox');
                let ratio = null;

                // Calculate SVG ratio
                if (viewBox) {
                    const vbHeight = viewBox.split(' ')[3];
                    const vbWidth = viewBox.split(' ')[2];
                    ratio = vbWidth / vbHeight;
                }

                // Set fill
                if (this.fill !== null) {
                    svgElm.setAttribute('fill', this.fill);
                }

                // Set height
                if (this.height !== null) {
                    svgElm.setAttribute('height', this.height);

                    // If we override height but no width, set width as well to maintain aspect ratio
                    if (this.width === null) {
                        svgElm.setAttribute('width', ratio ? this.height * ratio : '100%');
                    }
                }

                // Set stroke
                if (this.stroke !== null) {
                    svgElm.setAttribute('stroke', this.stroke);
                }

                if (this.strokeWidth !== null) {
                    svgElm.setAttribute('stroke-width', this.strokeWidth);
                }

                // Set stroke to all children
                if (this.strokeAll !== null) {
                    let children;

                    if (typeof svgElm.children !== 'undefined') {
                        children = Array.from(svgElm.children);
                    } else {
                        children = Array.from(svgElm.childNodes).filter(f => f.nodeType === 1);
                    }

                    children.forEach(s => s.setAttribute('stroke', this.strokeAll));
                }

                // Set viewBox
                if (this.viewBox !== null) {
                    svgElm.setAttribute('viewBox', this.viewBox);
                }

                // Set width
                if (this.width !== null) {
                    svgElm.setAttribute('width', this.width);

                    // If we override width but no height, set height as well to maintain aspect ratio
                    if (this.height === null) {
                        svgElm.setAttribute('height', ratio ? this.width / ratio : '100%');
                    }
                }

                // If trim is set and no viewBox, auto-generate viewBox values
                if (this.trim && this.viewBox === null && typeof svgElm.getBBox === 'function') {
                    let bbox = svgElm.getBBox();
                    svgElm.setAttribute(
                        'viewBox',
                        [bbox.x, bbox.y, bbox.width, bbox.height].join(' '),
                    );
                }
            });

            // Copy any potential prop input to data, so we don't try and manipulate the prop
            this.cssClasses = this.css;

            /**
           * CSS magic
           */
            // Convert string css to array
            if (typeof this.cssClasses === 'string') {
                this.cssClasses = this.cssClasses.split(/\s+/);
            }

            // Convert array css to object
            if (Array.isArray(this.cssClasses)) {
                let css = {};
                this.cssClasses.forEach(val => css[`${val}`] = true);
                this.cssClasses = css;
            }

            // Append svg-icon class
            this.cssClasses = Object.assign(this.cssClasses, { 'svg-icon': true });

            // Add spin class if spin is true
            if (this.spin) {
                this.cssClasses = Object.assign(this.cssClasses, { 'spin': true, 'fa-spin': true });
            }

            // Add flip vertical class if this is set
            if (this.flip === 'vertical') {
                this.cssClasses = Object.assign(this.cssClasses, { 'flip-vertical': true });
            }

            // Add flip horizontal class if this is set
            if (this.flip === 'horizontal') {
                this.cssClasses = Object.assign(this.cssClasses, { 'flip-horizontal': true });
            }
        },
    },
};
</script>

<style lang="scss">
.svg-icon {
    display: inline-block;
    fill: currentColor;
    svg {
        display: block; // important so that there is no whitespace of the inline svg tag.
    }
}
.svg-icon.flex {
    display: flex;
}

.svg-icon.flip-horizontal {
    transform: scale(-1, 1);
}

.svg-icon.flip-vertical {
    transform: scale(1, -1);
}

.svg-icon.spin {
    animation: fa-spin 1s 0s infinite linear;
}

@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
