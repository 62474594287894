<template>
    <span
        class="tooltip"
        @mouseenter="show && onMouseEnter()"
        @mouseleave="show && onMouseLeave()"
    >
        <slot />
    </span>
</template>

<script>

const leftPos = (e, tooltip) => ({ left: `${e.pageX - tooltip.w - 20}px`, top: `${e.pageY - tooltip.h / 2}px` });
const rightPos = (e, tooltip) => ({ left: `${e.pageX + 20}px`, top: `${e.pageY - tooltip.h / 2}px` });
const topPos = (e, tooltip) => ({
    left: `${e.pageX - (tooltip.w / 2)}px`,
    top: `${e.pageY - (tooltip.h + 10)}px`,
});
const botPos = (e, tooltip) => ({
    left: `${e.pageX - (tooltip.w / 2)}px`,
    top: `${e.pageY + 20}px`,
});

const positions = new Map([
    ['top', {
        handler(e, tooltip, parent) {
            return (e.pageX + 10 < (tooltip.w / 2)
                ? rightPos
                : e.pageX + 10 > parent.w - (tooltip.w / 2)
                    ? leftPos
                    : e.pageY < tooltip.h
                        ? botPos
                        : topPos)(e, tooltip, parent);
        },
    }],
    ['bottom', {
        handler(e, tooltip, parent) {
            return (e.pageX + 10 < (tooltip.w / 2)
                ? rightPos
                : e.pageX + 10 > parent.w - (tooltip.w / 2)
                    ? leftPos
                    : (parent.h - e.pageY) < tooltip.h
                        ? topPos
                        : botPos)(e, tooltip, parent);
        },
    }],
    ['left', {
        handler(e, tooltip, parent) {
            return (e.pageY < tooltip.h
                ? botPos
                : (parent.h - e.pageY) < tooltip.h
                    ? topPos
                    : e.pageX + 10 > tooltip.w
                        ? leftPos
                        : rightPos)(e, tooltip, parent);
        },
    }],
    ['right', {
        handler(e, tooltip, parent) {
            return (e.pageY < tooltip.h
                ? botPos
                : (parent.h - e.pageY) < tooltip.h
                    ? topPos
                    : (parent.w - e.pageX - 20) > tooltip.w
                        ? rightPos
                        : leftPos)(e, tooltip, parent);
        },
    }],
]);

// Throttle helper

function throttled(delay, fn) {
    let lastCall = 0;

    return function(...args) {
        const now = (new Date()).getTime();

        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;

        return fn(...args);
    };
}

export default {
    name: 'kk-tooltip',

    props: {
        text: {
            type: String,
            default: null,
        },
        show: {
            type: Boolean,
            default: true,
        },
        position: {
            type: String,
            default: 'top',
            validator: value => ['top', 'bottom', 'left', 'right'].includes(value),
        },
        throttle: {
            type: Number,
            default: 50,
        },
    },

    data() {
        return {
            parent: null,
            element: null,
            positions: {
                x: 0,
                y: 0,
            },
        };
    },

    watch: {
        show() {
            this.removeTooltipElement();
            this.createTooltipElementAndAppendToParent();
        },
        text() {
            this.removeTooltipElement();
            this.createTooltipElementAndAppendToParent();
        },
    },

    beforeUnmount() {
        this.removeTooltipElement();
    },

    mounted() {
        this.createTooltipElementAndAppendToParent();
    },

    methods: {
        removeTooltipElement() {
            if (this.element) {
                this.element.remove();
            }
        },
        createTooltipElementAndAppendToParent() {
            if (this.show) {
                this.element = document.createElement('div');
                this.element.style.display = 'none';
                this.element.innerHTML = this.text;
                this.element.id = 'kk-tooltip-pop';
                this.element.className = 'kk-tooltip-pop';
                this.parent = document.getElementsByClassName('sitecontent')[0];
                this.parent.appendChild(this.element);

                // set positions
                Object.assign(this.positions, positions.get(this.position));
            }
        },
        onMouseEnter() {
            this.element.style.display = 'block';
            const throttledHandler = throttled(this.throttle, this.onMouseMove);
            this.$el.addEventListener('mousemove', throttledHandler, false);
        },
        onMouseLeave() {
            this.element.style.display = 'none';
        },
        onMouseMove(e) {
            Object.assign(this.element.style, positions.get(this.position).handler(
                e,
                { w: this.element.offsetWidth, h: this.element.offsetHeight },
                { w: this.parent.offsetWidth, h: this.parent.offsetHeight },
            ));
        },
    },
};
</script>

<style lang="scss">
.kk-tooltip-pop {
    position: absolute;
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.699);
    color: #FFF;
    white-space: break-spaces;
    max-width: 500px;
    border-radius: 4px;
    font-size: 14px;
    font-family: inherit;
    z-index: 9001;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
