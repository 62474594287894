<template>
    <div v-if="title || subTitle" class="kk-form-title" :class="{ inline }">
        <label
            v-if="title"
            :style="{ color }"
            class="title"
            v-text="title"
        />
        <span v-if="subTitle" class="sub-title" v-text="subTitle" />
    </div>
</template>

<script>

export default {
    name: 'kk-form-title',

    props: {
        // Label/title text for input
        title: {
            type: String,
            default: '',
        },
        // sub-label/subtitle text. Used to describe title
        subTitle: {
            type: String,
            default: '',
        },

        // Wether to display block as inline or not
        inline: {
            type: Boolean,
            default: false,
        },

        // Color of title
        color: {
            type: String,
            default: '#25befe',
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    margin: 0;
    color: #25befe;
    font-size: 0.7rem;
    display: block;
    font-weight: 700;
}
.sub-title {
    font-size: .7em;
}
.kk-form-title {
    margin-bottom: 5px;
    &.inline {
        display: inline-block;
    }
}
</style>
