<template>
    <div v-if="type === 'modal'">
        <kk-modal-v2
            v-if="showContactModal"
            class="contacts-modal"
            :cancel-button-label="addNewMode ? trans('shared.Forrige') : trans('shared.Avbryt')"
            :ok-button-label="addNewMode ? trans('shared.Legg til') : (okButtonText?.length ? okButtonText : trans('shared.Velg'))"
            :ok-button-disabled="addNewMode ? isAddButtonDisabled : !isAnyContactSelected"
            type="separated"
            size="medium"
            @hide="addNewMode ? previousMode() : closeModal()"
            @ok="addNewMode ? addButtonClicked() : $emit('update:modelValue', valueChanged)"
        >
            <template #title>
                <template v-if="addNewMode">
                    {{ useCustomerLabel ? trans('orders.Kunde') : trans('shared.Kontakt') }}
                </template>
                <template v-else-if="modalTitle">
                    {{ modalTitle }}
                </template>
                <template v-else>
                    {{ useCustomerLabel ? trans('orders.Velg kunde') : trans('shared.Velg kontakt') }}
                </template>
            </template>
            <template #content>
                <div :class="[$options.name, ...classes]" @touchmove.stop>
                    <template v-if="addNewMode">
                        <new-contact
                            ref="newContactComponent"
                            :contact-options="contactOptions"
                            :categories="categories"
                            :disable-button="disableButton"
                            :use-customer-label="useCustomerLabel"
                            :contact-person-mode="contactPersonMode"
                            :new-mode-contact="newModeContact"
                            :primary="primary"
                            :mobile-view="mobileView"
                            @new-contact="createContact"
                            @open-creation="openNewMode"
                            @activate-spinner="activateSpinner"
                            @contact-person-mode="setContactPersonMode"
                            @disable-add-button="disableAddButton"
                        />
                    </template>
                    <template v-else>
                        <slot name="content-top" />

                        <contacts-list
                            :contact-options="contactOptions"
                            :categories="categories"
                            :last-used="lastUsed"
                            :add-new="addNew"
                            :modelValue="valueChanged"
                            :contacts="contacts"
                            :primary="primary"
                            :max="max"
                            :mobile="mobile"
                            :classes="classes"
                            @contact-person-mode="setContactPersonMode"
                            @new-mode-contact="setNewModeContact"
                            @open-creation="openNewMode"
                            @update:modelValue="setContactList"
                        />
                    </template>
                </div>
            </template>
        </kk-modal-v2>
        <customer-vendor-settings
            v-if="settingsOpen"
            :settings-open="settingsOpen"
            :settings-error="settingsError"
            @settings-status="changeSettingsStatus"
        />
    </div>
    <div v-else :class="[$options.name, ...classes]" @touchmove.stop>
        <template v-if="addNewMode">
            <new-contact
                ref="newContactComponent"
                :contact-options="contactOptions"
                :categories="categories"
                :disable-button="disableButton"
                :use-customer-label="useCustomerLabel"
                :contact-person-mode="contactPersonMode"
                :new-mode-contact="newModeContact"
                :primary="primary"
                :mobile-view="mobileView"
                @new-contact="createContact"
                @open-creation="openNewMode"
                @activate-spinner="activateSpinner"
                @contact-person-mode="setContactPersonMode"
                @disable-add-button="disableAddButton"
            />
            <div class="kk-contacts-normal-actions">
                <kk-button type="basic" :big="mobileView" @click="previousMode">
                    {{ trans('shared.Forrige') }}
                </kk-button>
                <kk-button
                    :disabled="isAddButtonDisabled"
                    :big="mobileView"
                    @click="addButtonClicked"
                >
                    {{ trans('shared.Legg til') }}
                </kk-button>
            </div>
        </template>
        <template v-else>
            <slot name="content-top" />

            <contacts-list
                :contact-options="contactOptions"
                :categories="categories"
                :last-used="lastUsed"
                :add-new="addNew"
                :modelValue="valueChanged"
                :contacts="contacts"
                :primary="primary"
                :max="max"
                :mobile="mobile"
                :classes="classes"
                @contact-person-mode="setContactPersonMode"
                @new-mode-contact="setNewModeContact"
                @open-creation="openNewMode"
                @update:modelValue="setContactList"
            />
            <div class="kk-contacts-normal-actions">
                <kk-button
                    v-if="!hideCancel"
                    type="basic"
                    :big="mobileView"
                    @click="closeModal"
                >
                    {{ trans("shared.Avbryt") }}
                </kk-button>
                <kk-button
                    v-if="!hideOk"
                    :disabled="!isAnyContactSelected"
                    :class="{ 'align-right': hideCancel }"
                    :big="mobileView"
                    @click="$emit('update:modelValue', valueChanged)"
                >
                    {{ okButtonText?.length ? okButtonText : trans("shared.Velg") }}
                </kk-button>
            </div>
        </template>
        <customer-vendor-settings
            v-if="settingsOpen"
            :settings-open="settingsOpen"
            :settings-error="settingsError"
            @settings-status="changeSettingsStatus"
        />
    </div>
</template>

<script src="./kk-contacts.js"></script>

<style lang="scss" scoped>
.kk-contacts {
    display: flex;
    flex-direction: column;
    width: auto !important;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    &.mobile {
        width: 100%;
        height: 100%;
        overflow: scroll;
        padding-bottom: 15px;
    }

    &.desktop {
        width: 280px;
        min-height: 450px;
        overflow: auto;
    }
}
.kk-contacts-normal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacing-2_5);
}
.align-right {
    margin-left: auto;
}
</style>
