<template>
    <div :class="[$options.name, conditionalClasses]" :style="{'--parent-height': `${parentHeight}px`}">
        <div
            v-if="arrow"
            class="arrow"
            :style="{'z-index': zIndex + 1 }"
        />
        <div
            v-click-outside="emitHide"
            class="box"
            tabindex="0"
            :style="{'z-index': zIndex}"
            @keydown.escape="onKeyEscape"
        >
            <slot name="content" />
        </div>
    </div>
</template>

<script src="./kk-popup-desktop.js"></script>

<style lang="scss" scoped>
@import "../../sass/variables.scss";
@import "../../sass/unset.scss";

.kk-popup-desktop {
    display: flex;
    flex-direction: column;
    outline: unset;
    position: relative;

    .arrow {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        left: calc(50% - 8px);
        border-width: 1px 0 0 1px;
        border-color: var(--gray-100);
        border-style: solid;
        background: white;
    }

    .box {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px;
        border-radius: 3px;
        background-color: white;
        border: 1px solid var(--gray-100);
        box-shadow: 0 4px 20px 10px #00000021;
    }
}

.up {
    flex-direction: column-reverse;

    .arrow {
        transform: rotate(-135deg);
        bottom: calc(10px + var(--parent-height));
    }

    .box {
        bottom: calc(18px + var(--parent-height));
    }
}

.down {
    flex-direction: column;

    .arrow {
        transform: rotate(45deg);
        top: 10px;
    }

    .box {
        top: 18px;
    }
}
</style>
