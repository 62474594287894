<template>
    <div
        class="kk-canvas"
        @mousedown="onMouseDown"
        @mousemove.prevent="onMouseMove"
        @mousewheel.prevent="onMouseWheel"
        @DOMMouseScroll.prevent="onMouseWheel"
        @mouseup.prevent="onMouseUp"
        @mouseleave.prevent="onMouseUp"
        @mouseenter.prevent="onMouseUp"
    >
        <canvas ref="canvas" :title="title" />
    </div>
</template>

<script src="./kk-canvas.js"></script>

<style lang="scss" scoped>

.kk-canvas {
    overflow: hidden;
    cursor: grab;
    line-height: 0;
    outline: none;

    &:active {
        cursor: grabbing;
    }
}
</style>
