<template>
    <transition name="kk-modal-v2">
        <!-- Dynamically use v-if or v-show based on useVShow prop -->
        <div
            v-if="!useVShow ? showModal : true"
            v-show="useVShow ? showModal : true"
            class="kk-modal-v2"
            tabindex="0"
        >
            <div
                ref="modal"
                v-click-outside="clickOutside"
                class="dialogbox"
                :class="[`dialogbox-${size}`]"
            >
                <header class="header" :class="{ 'header-separated': isTypeSeparated }">
                    <slot name="title">
                        <span /> <!-- if there is no title provided the put empty span to keep the layout -->
                    </slot>
                    <kk-icon-button
                        v-if="closeButton"
                        :icon="['fal', 'times']"
                        :size="'medium'"
                        button-type="button"
                        @click="$emit('hide')"
                    />
                </header>

                <section
                    class="content-section"
                    :class="{
                        'content-section-no-padding-bottom': footer,
                        'content-section-separated': isTypeSeparated,
                    }"
                >
                    <slot name="content" />
                </section>

                <footer v-if="footer" class="footer" :class="{ 'footer-separated': isTypeSeparated }">
                    <slot name="footer">
                        <kk-button
                            v-if="showCancelButton"
                            type="basic"
                            icon
                            button-type="button"
                            @click="$emit('hide')"
                        >
                            {{ cancelButtonLabel ?? trans('shared.Avbryt') }}
                        </kk-button>
                        <kk-button
                            v-if="showOkButton"
                            :type="okButtonType"
                            :disabled="okButtonDisabled"
                            :loading="loading"
                            icon
                            button-type="button"
                            @click="$emit('ok')"
                        >
                            {{ okButtonLabel ?? trans('shared.OK') }}
                        </kk-button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script src="./kk-modal-v2.js"></script>

<style lang="scss" src="./kk-modal-v2.scss" scoped></style>
